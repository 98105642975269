import React from "react";
import {TextField, Typography} from "@mui/material";
import "../styles/Select.css"
import Grid from "@mui/material/Grid";
import { QueryType, StringArrayFilters } from "./SearchWithProvider/types";

const formatValue = (v: number | string) => {
  if (v === '' || v === null || v === undefined) {
    return null;
  }
  let value: number | null = Number(v);
  if (Number.isNaN(value)) {
    value = null
  }
  return value;
}

const DEFAULT_MIN_MAX = ['', ''];

type Props = {
  title?: string | JSX.Element,
  query: QueryType,
  attribute: keyof StringArrayFilters,
  constructRange: (min: number | null, max: number | null, attribute: string) => void
}

const MinMaxRangeSelect = ({title, query, attribute, constructRange}: Props) => {
  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [, max] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

    const newMin = formatValue(event.target.value);
    const newMax = formatValue(max);

    constructRange(
      newMin,
      newMax,
      attribute
    );
  }

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [min] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

    const newMin = formatValue(min);
    const newMax = formatValue(event.target.value);

    constructRange(
      newMin,
      newMax,
      attribute
    );
  }

  const [min, max] = query.filters[attribute] ?? DEFAULT_MIN_MAX;

  return (
    <Grid container spacing={2} sx={{ alignItems: 'flex-end', marginTop: 1 }}>
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <Typography variant="body1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <TextField
          onChange={handleMaxChange}
          value={max}
          type="number"
          id="max-age" label="Max age (older)"
          variant="standard"
        />
      </Grid>
      <Grid item xs={4} style={{ paddingTop: 0 }}>
        <TextField
          onChange={handleMinChange} 
          value={min}
          type="number"
          id="min-age" label="Min age (younger)"
          variant="standard"
        />
      </Grid>
    </Grid>
  )
}

export default MinMaxRangeSelect;
